import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from 'react-tooltip'
import { TestType } from "../../app/models/testType";
import { Modem } from "../../app/models/modem";
import { useStore } from "../../app/stores/store";
import moment from 'moment'
import { TestResult } from "../../app/models/testResult";

interface Props {
  date: Date;
  modem: Modem;
  testType: TestType;
}

export default observer(function SimResultBox({date, modem, testType}: Props) {

  const {testResultStore} = useStore();

  const startDate = date;
  const endDate = moment(startDate).add(1, 'minute').toDate();

  const foundResult = testResultStore.testResults.find(s => s.modemId == modem.id
    && s.testTypeId == testType.id
    && s.createdAt >= startDate && s.createdAt <= endDate);

  function testColour(testResult: TestResult | undefined) {
    if(testResult) {
      if(testResult.success) return 'bg-green-400';
      else return 'bg-red-400';
    }
    else {
      return 'bg-blue-100 dark:bg-gray-800';
    }
  }

  function timeBorder(date: Date) {
    if(date.getMinutes() == 0) return "border border-gray-400 border-opacity-80 ";
    else if(date.getMinutes() == 30) return "border border-gray-500 border-opacity-60";
    else if(date.getMinutes() == 15 || date.getMinutes() == 45) return "border border-gray-500 border-opacity-40";
    else return "";
  }

  return (
    <>
      <button 
      className={`bars ${testColour(foundResult)} flex-1 h-8 rounded hover:opacity-80 ${timeBorder(date)}`}
      ></button>
    </>
  )
})

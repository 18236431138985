import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Modem } from "../models/modem";

export default class ModemStore {

    modemRegistry = new Map<number, Modem>();
    selectedModem: Modem | undefined = undefined;
    loading: boolean = false;
    polling: boolean = false;
    toggling: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get allModems() {
        return Array.from(this.modemRegistry.values())
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }

    get modemCount()
    {
        return this.modemRegistry.size;
    }

    wsModemUpdate = (modem: Modem) => {
        runInAction(() => {
            this.setModem(modem);
        })
    }

    public loadModems = async () => {
        this.setLoading(true);
        try {
            const result = await agent.Modems.list();
            result.forEach(modem => {
                this.setModem(modem);
            });
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    public loadModem = async (id: number) => {
        this.selectedModem = undefined;
        let modem = this.modemRegistry.get(id);
        if(modem) {
            this.selectedModem = modem;
            return modem;
        }
        else{
            this.setLoading(true);
            try {
                modem = await agent.Modems.details(id);
                this.setModem(modem);
                runInAction(() => {
                    if(modem)
                    {
                        this.selectedModem = modem;
                    }
                })
                this.setLoading(false);
                return modem;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }

    private setModem = (modem: Modem) => {
        this.modemRegistry.set(modem.id, modem);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }
}

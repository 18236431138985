import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Modem } from "../models/modem";
import { Pagination, PagingParams } from "../models/pagination";
import { TestResult } from "../models/testResult";
import { store } from "./store";

export default class WebSocketStore {

    hubConnection: HubConnection | null = null;

    loading: boolean = false;

    url: string = process.env.REACT_APP_RESULTS_URL ?? "";

    constructor() {
        makeAutoObservable(this);
    }

    createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.url, {
                accessTokenFactory: () => store.userStore.user?.token!
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection.start()
            .catch(error => console.log('Error starting connection', error));
        
        this.hubConnection.on('LoadRecent', (testResults: TestResult[]) => {
            runInAction(() => {
                testResults.forEach(testResult => {
                    testResult.createdAt = new Date(testResult.createdAt + 'Z');
                })
                store.testResultStore.wsLoadedRecent(testResults);
            });
        })

        this.hubConnection.on('ReceiveOne', (testResult: TestResult) => {
            runInAction(() => {
                testResult.createdAt = new Date(testResult.createdAt + 'Z');
                store.testResultStore.wsLoadOne(testResult);
            });
        })

        this.hubConnection.on('ModemUpdate', (modem: Modem) => {
            runInAction(() => {
                store.modemStore.wsModemUpdate(modem);
            });
        })
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch(error => console.log('Error stopping connection', error));
    }
}

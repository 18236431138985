import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Header from "../../app/layout/navbar/Header";
import ModemList from "./ModemList";
import { useStore } from "../../app/stores/store";
import moment from 'moment'

export default observer(function ModemPagination() {

    const {timeStore} = useStore();
    const {start, end, goBack, goForward, liveTimeEnabled, setLiveTime} = timeStore;

    return (
        <div className="mb-1 container flex flex-col items-center md:flex-row justify-between">
            <button
                type="button"
                onClick={() => goBack()}
                className="mx-1 inline-flex items-center px-3 py-1 border border-gray-300 dark:border-none rounded-md shadow-sm text-sm font-medium 
                text-gray-700 dark:text-gray-400 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2"
            >
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" 
            xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
            </button>
            <div className="text-sm text-slate-500">{moment(start).format('MMM Do YY, h:mm')} to {moment(end).format('MMM Do YYs, h:mm')}</div>
            <div className="flex items-center">
                <button
                    type="button"
                    onClick={() => goForward()}
                    className="mx-1 inline-flex items-center px-3 py-1 border border-gray-300 dark:border-none rounded-md shadow-sm text-sm font-medium 
                    text-gray-700 dark:text-gray-400 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2"
                >
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" 
                xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                </button>

                <button
                    type="button"
                    onClick={() => setLiveTime(!liveTimeEnabled)}
                    className={`mx-1 inline-flex items-center px-3 py-1 border border-gray-300 dark:border-none rounded-md shadow-sm text-sm font-medium 
                    text-gray-700 dark:text-gray-400 ${liveTimeEnabled ? 'bg-green-100 dark:bg-green-900' : 'bg-red-300 dark:bg-red-800'} hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2`}
                >
                    <div className="relative">
                        {liveTimeEnabled ? (
                            <>
                                Live <div className="absolute bottom-4 left-8 w-2 h-2 rounded-full bg-green-500 animate-ping"></div>
                            </>
                        ) : (
                            <>Paused</>
                        )}
                    </div>
                </button>
            </div>
        </div>
    )
})

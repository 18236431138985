import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

export default observer(function LogoutButton(){

    const {userStore} = useStore();
    const {logout} = userStore;

    return (
      <button
      type="button"
      onClick={() => logout()}
      className="inline-flex items-center px-4 py-2 border border-gray-200 dark:border-black rounded-md 
      shadow-sm text-sm font-medium text-gray-500 bg-white dark:bg-gray-800 
      hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2"
    >
      Logout
    </button>
    )
})
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Modem } from "../../app/models/modem";
import ReactTimeAgo from 'react-time-ago'

interface Props {
    modem: Modem;
}

export default observer(function ModemWifiBadge({modem}: Props) {

  return (
    <>
      {modem.lastWifiCallback ? (
        <>
          <span className="text-blue-600 dark:text-blue-400 font-semibold bg-blue-200 dark:bg-blue-900 px-1 mx-2 rounded-md">
          💻 <ReactTimeAgo date={new Date(modem.lastWifiCallback!)} locale="en-US" timeStyle="twitter"/>
          </span>
        </>
        ) : (
          <span className="text-red-600 dark:text-red-400 font-semibold bg-red-200 dark:bg-red-900 px-1 mx-2 rounded-md">
          💻 Waiting For WiFi!
          </span>
        )
      }
    </>
  )
})

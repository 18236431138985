import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modem } from "../models/modem";
import { PaginatedResult } from "../models/pagination";
import { ServerError } from "../models/serverError";
import { TestResult } from "../models/testResult";
import { TestType } from "../models/testType";
import { User, UserFormValues } from "../models/user";
import { store } from "../stores/store";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if(token) config.headers!.Authorization = `Bearer ${token}`
    return config;
})

axios.interceptors.response.use(async response => {
    if(process.env.NODE_ENV === 'development') {
        await sleep(1000);
    }
    
    const pagination = response.headers['pagination'];
    if(pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }

    return response;

}, (error: AxiosError) => {
    
    const{data, status, config} = error.response!;
 
    switch(error.response?.status) {
        case 401:
            toast.error('Unauthorised');
            break;
        case 404:
            toast.error('Not Found');
            break;
        case 500:
            store.commonStore.setServerError(data as ServerError);
            toast.error('Server Error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user)
}

const Modems = {
    list: () => requests.get<Modem[]>('/modems'),
    details: (id: number) => requests.get<Modem>(`/modems/${id}`)
}

const TestResults = {
    list: (params: URLSearchParams) => 
        axios.get<PaginatedResult<TestResult[]>>('/testresults', {params}).then(responseBody),
    details: (id: string) => requests.get<TestResult>(`/testresults/${id}`),
    testTypes: () => requests.get<TestType[]>(`/testresults/testTypes`),
}

const agent = {
    Account,
    Modems,
    TestResults
}

export default agent;
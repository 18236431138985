import React, { useEffect } from 'react';
import '../layout/styles.scss'
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/store';
import { ToastContainer } from 'react-toastify';
import ModalContainer from '../common/modals/ModalContainer';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from '../../features/users/LoginPage';
import ProtectedRoute from '../common/routes/ProtectedRoute';
import ModemPage from '../../features/modems/ModemPage';

export default observer(function App() {

  const navigate = useNavigate();

  const {commonStore, userStore} = useStore();
  const {user} = userStore;

  // Check for a token, and if we have one get the user from the API
  useEffect(() => {
    if(commonStore.token) {
        userStore.getUser().finally(() => commonStore.setAppLoaded());
  } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore, navigate]);

  return (
      <>
          <ToastContainer position='bottom-right' hideProgressBar />
          <ModalContainer />
          <Routes>
              <Route path="/login" element={<LoginPage />} />

              <Route path='/' element={<ProtectedRoute outlet={<ModemPage />} />} />
          </Routes>
      </>
  );
})
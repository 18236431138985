import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import ModemStore from "./modemStore";
import TestResultStore from "./testResultStore";
import TestTypesStore from "./testTypesStore";
import TimeStore from "./timeStore";
import UserStore from "./userStore";
import WebSocketStore from "./webSocketStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    modemStore: ModemStore;
    testResultStore: TestResultStore;
    testTypeStore: TestTypesStore;
    timeStore: TimeStore;
    webSocketStore: WebSocketStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    modemStore: new ModemStore(),
    testResultStore: new TestResultStore(),
    testTypeStore: new TestTypesStore(),
    timeStore: new TimeStore(),
    webSocketStore: new WebSocketStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
import { makeAutoObservable, reaction } from "mobx";
import moment from 'moment'
import { ServerError } from "../models/serverError";

export default class TimeStore {

    minutesWide: number = 30;

    liveTimeEnabled: boolean = true;

    startTime: Date = new Date();   // furthest away
    endTime: Date = new Date();     // most recent

    times: Date[]= [];
    now: Date = new Date();
    dates: Date[] = [];

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.minutesWide, // this is what we are reacting to
            minutes => {
                this.setupDates();
            }
        )
    }

    get start() {
        return this.startTime;
    }

    get end() {
        return this.endTime;
    }

    get liveTime() {
        return this.liveTimeEnabled;
    }

    setLiveTime = (enabled: boolean) => {
        this.liveTimeEnabled = enabled;
    }

    checkLiveTime = () => {
        if(this.liveTimeEnabled) {
            const now = new Date();
            now.setSeconds(0,0);
            if(this.endTime < now) {
                this.goForward();
            }
        }
    }

    goBack = () => {
        this.liveTimeEnabled = false;
        this.endTime = this.startTime;
        this.setupDates();
    }

    goForward = () => {
        let target = moment(this.endTime).add(this.minutesWide, 'minute').toDate();
        if(target >= new Date()) {
            target = new Date();
            this.liveTimeEnabled = true;
        } else {
            this.liveTimeEnabled = false;
        }
        this.endTime = target;
        this.setupDates();
    }
    
    private setupDates() {
        this.dates = [];
        // Go to the latest date (start), and work back to the most recent (end)
        for (let i = this.minutesWide; i >= 0; i--) {
            const date = moment(this.endTime).subtract(i, 'minute').toDate();
            date.setSeconds(0, 0);
            this.dates.push(date);
        }
        this.startTime = moment(this.endTime).subtract(this.minutesWide, 'minute').toDate();
    }

    setMinutesWidth = (minutes: number) => {
        this.minutesWide = minutes;
    }
}
import { useField } from "formik";
import React from "react";

interface Props{
    placeholder: string;
    name: string;
    label?: string;
    type?: string;
    icon?: React.ReactNode;
}

export default function MyTextInput(props: Props) {

    const [field, meta] = useField(props.name);

    return (
        <div>
            <label
                id={props.name}
                className="block block text-sm md:text-lg font-medium mb-1"
                htmlFor={props.name}>{props.label}</label>

            <input
                {...field}
                className="form-input w-full rounded-none border-2 border-black py-3"
                autoComplete="on" 
                id={props.name}
                name={props.name}
                placeholder={props.placeholder}
                type={props.type}
            />

            {meta.error && (
                <div className="mt-5">
                    <div className="bg-black text-rose-300 text-md md:text-lg px-3 py-2">
                    <svg className="inline w-3 h-3 shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">
                        {meta.error}
                    </span>
                    </div>
                </div>
            )}
        </div>
    )
}
import { observer } from "mobx-react-lite"
import React from "react"
import DarkModeButton from "./DarkModeButton"
import LogoutButton from "./LogoutButton"

export default observer(function Header() {
    return (
        <header className="py-2 md:py-4 mb-1 bg-gray-100 dark:bg-gray-900 shadow-md">
            <div className="container flex flex-col items-center md:flex-row justify-between">
                <div>
                    <img className="pl-2" src="img/worldov.svg" width="80" height="80" alt="WorldOV" />
                </div>
                <div className="links flex space-x-2 items-center leading-none font-medium">
                    <DarkModeButton />
                    <LogoutButton />
                </div>
            </div>
        </header>
    )
})
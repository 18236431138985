import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Modem } from "../../app/models/modem";
import ReactTimeAgo from 'react-time-ago'

interface Props {
    modem: Modem;
}

export default observer(function ModemCellBadge({modem}: Props) {

  return (
    <>
      {modem.lastMobileCallback ? (
        <>
          <span className="text-purple-600 dark:text-purple-400 font-semibold bg-purple-200 dark:bg-purple-900 px-1 mx-2 rounded-md">
          🗼<ReactTimeAgo date={new Date(modem.lastMobileCallback!)} locale="en-US" timeStyle="twitter"/>
          </span>
        </>
        ) : (
          <span className="text-red-600 dark:text-red-400 font-semibold bg-red-200 dark:bg-red-900 px-1 mx-2 rounded-md">
          🗼 Waiting For Cell!
          </span>
        )
      }
    </>
  )
})

import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Modem } from "../models/modem";
import { TestType } from "../models/testType";

export default class TestTypesStore {

    testTypeRegistory = new Map<number, TestType>();
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get allTypes() {
        return Array.from(this.testTypeRegistory.values());
    }

    public loadTypes = async () => {
        this.setLoading(true);
        try {
            const result = await agent.TestResults.testTypes();
            result.forEach(testType => {
                this.setType(testType);
            });
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    private setType = (testType: TestType) => {
        this.testTypeRegistory.set(testType.id, testType);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }
}

import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

export default observer( function ModalContainer(){

    const {modalStore} = useStore();
    // opened={modalStore.modal.open}
    // onClose={modalStore.closeModal}
    // size='mini'

    return (
        <p>
            {modalStore.modal.body}
        </p>
    )
})
import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";

export default observer( function LoginPage() {
    
    return ( 
      <main className="bg-white">
        <div className="relative md:flex">
          {/* Content */}
          <LoginForm />
        </div>
      </main>
    )
})
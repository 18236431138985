import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Header from "../../app/layout/navbar/Header";
import SimResultRow from "./SimResultRow";
import { Modem } from "../../app/models/modem";
import { useStore } from "../../app/stores/store";
import { PagingParams } from "../../app/models/pagination";
import { format, formatDistanceToNow } from "date-fns";
import ModemWifiBadge from "./ModemWifiBadge";
import ModemCellBadge from "./ModemCellBadge";

interface Props {
    modem: Modem;
}

export default observer(function ModemRow({modem}: Props) {

  const {testTypeStore, testResultStore} = useStore();

  const {testTypeRegistory, loadTypes, allTypes} = testTypeStore;

  useEffect(() => {
      if(testTypeRegistory.size < 1) testTypeStore.loadTypes();
  }, [testTypeRegistory.size, testTypeStore, loadTypes]);

  function getDate(modem: Modem): string {
    if(modem.lastWifiCallback) {
      return `Wifi: ${formatDistanceToNow(new Date(modem.lastWifiCallback))}`;
    }
    else {
      return 'Waiting';
    }
  }

  return (
  <>
    <div className="py-2 bg-zinc-50 dark:bg-gray-900 shadow-sm py-3">
      <div className="container flex items-center justify-between pb-1">
        <h3 className="px-2 text-md font-bold text-gray-500 dark:text-gray-400 italic">{modem.name}</h3>
        <div>
          <ModemWifiBadge modem={modem} />
          <ModemCellBadge modem={modem} />
        </div>
      </div>
      {allTypes.map((testType) => (
        <SimResultRow modem={modem} testType={testType} key={testType.id} />
    ))}
    </div>
  </>
  )
})

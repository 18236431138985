import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router';
import LoadingComponent from '../../layout/LoadingComponent';
import { useStore } from '../../stores/store';

export type ProtectedRouteProps = {
  outlet: JSX.Element;
};

export default observer(function ProtectedRoute({outlet}: ProtectedRouteProps) {

    const loginPath = '/login';

    const {userStore, commonStore} = useStore();
    const {user} = userStore;
    const {appLoaded} = commonStore;
    
    const currentLocation = useLocation();

    if(user) {
        return outlet;
    } else if (appLoaded && !user) {
        return <Navigate to={loginPath} />;
    } else {
        return <LoadingComponent message='User authenticating, please wait...' />
    }
});
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from '../../stores/store';

export default observer(function DarkModeButton(){

    // Handle light modes
    const [darkMode, setDarkMode] = useState(() => {
        if(typeof(localStorage) !== "undefined"){
          const dark = localStorage.getItem('dark-mode');
          if (dark === null) {
            return true;
          } else {
            return dark === 'true';
          }
        }
      });
    
      useEffect(() => {
        localStorage.setItem('dark-mode', darkMode!.toString())
        if (darkMode) {
          document.documentElement.classList.add('dark')
        } else {
          document.documentElement.classList.remove('dark')
        }
      }, [darkMode]); 

    return (
        <>
            <button
                onClick={() => setDarkMode(!darkMode)}
                type="button"
                className="inline-flex items-center p-2 border border-gray-200 dark:border-black rounded-md shadow-sm text-sm font-medium text-gray-500 dark:text-gray-500 
                bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2"
            >
                {darkMode ? (
                    <>
                        <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                        ></path>
                        </svg>
                    </>
                 ) : (
                    <>
                        <svg
                        x-show="!dark"
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                        ></path>
                        </svg>
                    </>
                    )
                }
            </button>
        </>
    )
})
import React from "react";

interface Props {
    message?: string;
}

export default function LoadingComponent({message}: Props) {
    return (
        <>
            { message ? (
                <div className="w-full text-xl text-teal-700 font-bolder text-center">{message}</div>
                ) : (
                <div className="w-full text-md text-teal-500 text-center">Loading...</div>
            ) }
        </>
    )
}
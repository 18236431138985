import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import SimResultBox from "./SimResultBox";
import { TestType } from "../../app/models/testType";
import { Modem } from "../../app/models/modem";
import { useStore } from "../../app/stores/store";

interface Props {
  modem: Modem;
  testType: TestType;
}

export default observer(function SimResultRow({modem, testType}: Props) {

  const {timeStore} = useStore();
  const {dates} = timeStore;

  return (
    <div className="container bars py-0.5">
      <div className="flex space-x-1">
          <h2 className="w-32 text-gray-500 text-right pr-2">{testType.description}</h2>
          {dates.map((date) => (
            <SimResultBox date={date} modem={modem} testType={testType} key={date.toString()}/>
          ))}
      </div>
    </div>
  )
})

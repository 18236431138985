import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import ModemRow from "./ModemRow";
import { useStore } from "../../app/stores/store";

export default observer(function ModemList() {

  const {modemStore, webSocketStore} = useStore();

  const {modemRegistry, loadModems, allModems} = modemStore;

  useEffect(() => {
    webSocketStore.createHubConnection();
    
    return () => {
      webSocketStore.stopHubConnection();
    }
  }, [webSocketStore]);

  useEffect(() => {
      if(modemRegistry.size < 1) modemStore.loadModems();
  }, [modemRegistry.size, modemStore, loadModems]);

  return (
      <main>
          <div className="dark:bg-black space-y-2">
          {
              allModems.map(modem => {
                return (
                  <ModemRow modem={modem} key={modem.id} />
                )
              })
            }
          </div>
      </main>
  )
})

import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../stores/store";

export default observer(function Footer() {

    const {testResultStore} = useStore();

    return (
        <footer className="py-16 text-gray-700 dark:text-gray-100 bg-gray-100 dark:bg-black">
            <div className="container flex justify-between">
            <div className="text-black dark:text-gray-500">
            Powered by<span className="font-semibold"> WorldOV</span></div>
            </div>
        </footer>
    )
})
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Header from "../../app/layout/navbar/Header";
import ModemList from "./ModemList";
import { useStore } from "../../app/stores/store";
import ModemPagination from "./ModemPagination";
import Footer from "../../app/layout/Footer";

export default observer(function ModemPage() {

    const {timeStore} = useStore();
    const {setMinutesWidth, minutesWide, checkLiveTime} = timeStore;

    function pageMinutesWide() {
        return Math.min(Math.floor(window.innerWidth / 20.7), 90);
    }

    useEffect(() => {
        if(minutesWide != pageMinutesWide()) setMinutesWidth(pageMinutesWide());
    }, [pageMinutesWide, minutesWide]);

    const MINUTE_MS = 5000;

    useEffect(() => {
      const interval = setInterval(() => {
        checkLiveTime();
      }, MINUTE_MS);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    
    return (
        <div className="bg-zinc-100 dark:bg-black h-screen">
            <Header />
            <ModemPagination />
            <ModemList />
            <Footer />
        </div>
    )
})

import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import { TestResult } from "../models/testResult";
import { store } from "./store";

export default class TestResultStore {

    hubConnection: HubConnection | null = null;

    testResults: TestResult[] = [];
    loadedModems: number[] = [];

    loading: boolean = false;

    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    modemId: number | null = null;

    url: string = process.env.REACT_APP_RESULTS_URL ?? "";

    constructor() {
        makeAutoObservable(this);
        console.log();
    }

    get resultsByDate() {
        return Array.from(this.testResults)
        .sort((a, b) => a.createdAt!.getTime() - b.createdAt!.getTime());
    }

    wsLoadedRecent = (testResults: TestResult[]) => {
        runInAction(() => {
            this.testResults = testResults;
        })
    }

    wsLoadOne = (testResult: TestResult) => {
        runInAction(() => {
            this.testResults.unshift(testResult)
        })
    }

    private axiosParams = (modemId: number) => {
        const params = new URLSearchParams();
        params.append("pageNumber", this.pagingParams.pageNumber.toString());
        params.append("pageSize", this.pagingParams.pageSize.toString());
        params.append("modemId", modemId.toString());
        return params;
    }
    
    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    public loadTestResults = async (modemId: number) => {
        if(this.loadedModems.indexOf(modemId) < 0) {
            this.loadedModems.push(modemId);
        }
        this.setLoading(true);
        try {
            const result = await agent.TestResults.list(this.axiosParams(modemId));
            result.data.forEach(testResult => {
                this.setTestResult(testResult);
            });
            this.setPagination(result.pagination);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    private setTestResult = (testResult: TestResult) => {
        testResult.createdAt = new Date(testResult.createdAt + 'Z');
        this.testResults.push(testResult);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }
}
